<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-start auth-bg px-2 p-lg-5-update"
      >
        <b-col sm="12" md="12" lg="12" class="px-xl-2 mx-auto">
          <div>
            <!-- Brand logo-->
            <b-link class="brand-logo-update">
              <DefaultLogo />
            </b-link>
            <!-- /Brand logo-->
            <b-card-title title-tag="h2" class="font-weight-bold mb-0">
              {{ $t("user.InvitedPleaseWait") }}
            </b-card-title>
            <!-- form -->
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BRow, BCol, BCardText, BCardTitle, BLink } from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import AccountService from "@/libs/api/account-service";
import AuthService from "@/libs/api/auth-service";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,

    DefaultLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      dots: "."
    };
  },
  async mounted() {
    let response = await new AccountService().getInvite({
      token: this.$route.params.token
    });
    if (response && response.data) {
      localStorage.setItem("inviteData", JSON.stringify(response.data));
      if (response.data.is_registered_user) {
        const user = JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"));
        if (user && user.email === response.data.email) {
          this.$router.push("/");
        } else {
          new AuthService().logout({
            session_id: localStorage.getItem("sessionId")
          });
          this.$router.push("/");
        }
      } else {
        if (
          response.data.white_labeling &&
          response.data.white_labeling.theme
        ) {
          localStorage.setItem(
            "savedClass",
            response.data.white_labeling.theme
          );
          document.body.classList.remove(
            "blue-layout",
            "pink-layout",
            "red-layout",
            "orange-layout",
            "yellow-layout",
            "green-layout",
            "teal-layout",
            "cyan-layout"
          );
          document.body.classList.add(response.data.white_labeling.theme);
        }
        if (response.data.white_labeling && response.data.white_labeling.lang) {
          localStorage.setItem("lang", response.data.white_labeling.lang);
        }
        localStorage.setItem(
          "USER_ACCOUNT_WHITE_LABELING",
          JSON.stringify(Object.assign({}, response.data.white_labeling))
        );
        this.$router.push({ name: "new-user-onboarding" });
      }
    } else if (response && response.error && response.error.message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: response.error.title,
          text: response.error.message,
          icon: "EditIcon",
          variant: "danger"
        }
      });
      this.$router.push("/dashboard");
      localStorage.removeItem("inviteData");
    }
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
</style>
